import React, { Component } from "react";

export default class ExperienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="801.92126"
        height="597.4574"
        viewBox="0 0 801.92126 597.4574"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <circle id="b" cx="436.31396" cy="42" r="18.93406" fill="#bf5700" />
        <path
          id="c-1003"
          d="M434.43768,49.22279c-.42596,.00072-.84058-.13708-1.1814-.39262l-.02112-.01585-4.44977-3.40392c-.8667-.66447-1.0307-1.90575-.36621-2.77248,.66443-.86673,1.90576-1.0307,2.77246-.36623h0l2.8822,2.21021,6.81079-8.88542c.66418-.86635,1.90491-1.03024,2.77124-.36607l.00006,.00003,.00055,.00043-.0423,.05869,.0434-.05869c.86536,.66507,1.02887,1.90515,.3656,2.77185l-8.01099,10.44653c-.37567,.48787-.95709,.77295-1.57281,.77116l-.00171,.00238Z"
          fill="#fff"
        />
        <circle id="d" cx="436.31396" cy="170" r="18.93405" fill="#bf5700" />
        <path
          id="e-1004"
          d="M434.43768,177.22278c-.42596,.0007-.84058-.13708-1.1814-.39264l-.02112-.01584-4.44977-3.40393c-.8667-.66446-1.0307-1.90573-.36621-2.77246s1.90576-1.03067,2.77246-.36621l2.8822,2.21021,6.81079-8.88541c.66418-.86633,1.90491-1.03021,2.77124-.36603l.00006,.00003,.00055,.00043-.0423,.05869,.0434-.05869c.86536,.66507,1.02887,1.90515,.3656,2.77185l-8.01099,10.44653c-.37567,.48785-.95709,.77295-1.57281,.77115l-.00171,.00232Z"
          fill="#fff"
        />
        <path
          d="M483.8642,368.95905c-.23236-5.93018,4.38666-10.9259,10.31683-11.15823,.63226-.02478,1.2655,.00635,1.89227,.09299l17.341-34.02234,9.79852,17.25595-17.96954,29.66269c-1.20624,5.84369-6.92139,9.60303-12.76508,8.39679-4.88965-1.00934-8.45087-5.23767-8.61407-10.22778l.00006-.00006Z"
          fill="#ffb7b7"
        />
        <polygon
          points="564.71002 583.19397 551.10101 583.19299 544.62701 530.70203 564.71198 530.703 564.71002 583.19397"
          fill="#ffb7b7"
        />
        <path
          d="M568.18018,596.3855l-43.88049-.00165v-.55499c.00067-9.43243,7.64709-17.07874,17.07953-17.07922h.0011l26.80072,.0011-.00085,17.63477Z"
          fill="#2f2e41"
        />
        <polygon
          points="638.48297 583.19397 624.87402 583.19299 618.40002 530.70203 638.48499 530.703 638.48297 583.19397"
          fill="#ffb7b7"
        />
        <path
          d="M641.95349,596.3855l-43.88049-.00165v-.55499c.00067-9.43243,7.64709-17.07874,17.07953-17.07922h.0011l26.80072,.0011-.00085,17.63477Z"
          fill="#2f2e41"
        />
        <path
          d="M504.76678,339.34869l-5.94092,9.90152s9.90149,0,11.88177,14.8522l7.9212-9.90149-13.86206-14.85223Z"
          fill="#e5e5e5"
        />
        <path
          d="M608.73242,318.55554s15.84241,41.58627,18.81281,67.33014c2.9704,25.7439,7.9212,94.06415,7.9212,94.06415l9.90155,88.12335-27.72418,3.96057-11.88184-89.11346-26.73401-88.12335-5.94092,88.12329-4.95074,80.20215-25.7439,.99011-1.98035-83.17261-10.89166-105.94598,5.94092-50.49762,73.27112-5.94073Z"
          fill="#2f2e41"
        />
        <polygon
          points="589.42401 192.312 599.32599 199.242 612.198 328.952 531.99597 330.93201 542.888 240.82899 552.789 215.08501 559.93597 205.636 589.42401 192.312"
          fill="#e5e5e5"
        />
        <path
          d="M591.40485,285.38553l3.02985-89.56699s5.88153,.45349,7.86182,3.42401,34.65521,8.91135,34.65521,8.91135l-5.94092,92.08389s24.75372,73.27106,15.84241,77.23169-24.75372,2.97046-24.75372,2.97046l-30.69464-95.05441Z"
          fill="#2f2e41"
        />
        <path
          d="M543.38263,273.99881l10.50378-60.36459-23.37579,15.80786,5.94092,43.56662-4.95074,29.7045s-18.81287,82.18243-12.87201,85.15289,12.87201,0,12.87201,0l11.88184-83.17264v-30.69464Z"
          fill="#2f2e41"
        />
        <path
          d="M537.44171,227.46179l-6.93109,1.98032-2.97046,39.60599-4.95074,34.65524-23.76355,42.57635s11.88177-7.92117,16.83252,14.85226l26.17065-45.56387-4.38733-88.10629Z"
          fill="#2f2e41"
        />
        <path
          d="M591.2995,360.83893c.92328-5.86249,6.42419-9.86649,12.28668-8.94324,.62506,.09845,1.24017,.25189,1.83826,.45859l23.61572-30.00879,6.26233,18.82983-23.38599,25.61002c-2.31775,5.49835-8.65393,8.07678-14.15228,5.75903-4.60071-1.93933-7.27332-6.77856-6.46472-11.70544Z"
          fill="#ffb7b7"
        />
        <polygon
          points="631.50598 350.23999 622.59497 361.13199 610.71301 340.33899 615.66302 331.42801 631.50598 350.23999"
          fill="#e5e5e5"
        />
        <path
          d="M629.52563,211.61938l7.9212-3.9606s5.94092,7.9212,6.93103,14.85226,5.94092,15.84238,5.94092,20.79312,13.86212,51.48776,13.86212,51.48776l-31.68488,64.35977s-6.93103-23.76358-21.78326-25.7439l24.75372-34.65527-13.86212-24.75372,7.92126-62.37943Z"
          fill="#2f2e41"
        />
        <path
          d="M800.92126,596.99609H419.92126c-.55231,0-1-.44769-1-1s.44769-1,1-1h381c.55231,0,1,.44769,1,1s-.44769,1-1,1Z"
          fill="#cbcbcb"
        />
        <path d="M365,212H0v-84H365v84Z" fill="#fff" />
        <path
          d="M52.67781,155.77438c-1.96416,0-3.55641,1.59225-3.55641,3.55643s1.59225,3.5564,3.55641,3.5564H312.33719c1.96414,0,3.55643-1.59225,3.55643-3.5564s-1.59229-3.55643-3.55643-3.55643H52.67781Z"
          fill="#bf5700"
        />
        <path
          d="M52.67781,177.11282c-1.96416-.00415-3.55977,1.58475-3.5639,3.54892-.00415,1.96414,1.58475,3.55975,3.5489,3.5639H216.82214c1.96414,0,3.55643-1.59225,3.55643-3.5564s-1.59229-3.55643-3.55643-3.55643H52.67781Z"
          fill="#bf5700"
        />
        <path d="M365,212H0v-84H365v84ZM6,206H359v-72H6v72Z" fill="#e5e5e5" />
        <path d="M365,340H0v-84H365v84Z" fill="#fff" />
        <path
          d="M52.67781,283.77438c-1.96416,0-3.55641,1.59225-3.55641,3.55643s1.59225,3.5564,3.55641,3.5564H312.33719c1.96414,0,3.55643-1.59225,3.55643-3.5564s-1.59229-3.55643-3.55643-3.55643H52.67781Z"
          fill="#e6e6e6"
        />
        <path
          d="M52.67781,305.11282c-1.96416-.00415-3.55977,1.58475-3.56392,3.54892-.00414,1.96414,1.58476,3.55975,3.54892,3.5639H216.82214c1.96414,0,3.55643-1.59225,3.55643-3.55643s-1.59229-3.5564-3.55643-3.5564H52.67781Z"
          fill="#e6e6e6"
        />
        <path d="M365,340H0v-84H365v84ZM6,334H359v-72H6v72Z" fill="#e5e5e5" />
        <path d="M368,84H3V0H368V84Z" fill="#fff" />
        <path
          d="M55.67781,27.77438c-1.96414,.00359-3.5535,1.59874-3.54993,3.56288,.00357,1.95909,1.59084,3.54636,3.54993,3.54993H315.33722c1.96417-.00359,3.55353-1.59874,3.54993-3.56288-.0036-1.95909-1.59082-3.54636-3.54993-3.54993H55.67781Z"
          fill="#bf5700"
        />
        <path
          d="M55.67781,49.11281c-1.96414-.00414-3.55977,1.58476-3.5639,3.5489s1.58476,3.55977,3.5489,3.5639H219.82214c1.96414,.00359,3.5593-1.58577,3.5629-3.54993,.00357-1.96414-1.58578-3.5593-3.54993-3.56288-.00433-.00002-.00864-.00002-.01297,0H55.67781Z"
          fill="#bf5700"
        />
        <path d="M368,84H3V0H368V84ZM9,78H362V6H9V78Z" fill="#e5e5e5" />
        <path
          d="M739.24805,597.4574v-72.34003s28.19165,51.28601,0,72.34003Z"
          fill="#f1f1f1"
        />
        <path
          d="M740.9895,597.4447l-53.28961-48.92126s56.84515,13.91553,53.28961,48.92126Z"
          fill="#f1f1f1"
        />
        <circle
          id="f"
          cx="484.31396"
          cy="347.84558"
          r="18.93405"
          fill="#bf5700"
        />
        <path
          id="g-1005"
          d="M482.43768,355.06836c-.42596,.00073-.84058-.13708-1.1814-.39261l-.02112-.01587-4.44977-3.40393c-.8667-.66446-1.03064-1.90573-.36621-2.77243,.66449-.86673,1.90576-1.03067,2.77246-.36621h0l2.8822,2.21021,6.81079-8.88541c.66418-.86633,1.90497-1.03021,2.7713-.366h0l.00055,.00043-.0423,.05869,.0434-.05869c.86536,.66507,1.02887,1.90515,.3656,2.77185l-8.01099,10.44653c-.37567,.48785-.95709,.77295-1.57281,.77115l-.00171,.00229Z"
          fill="#fff"
        />
        <circle cx="561.58681" cy="166.8501" r="27.103" fill="#ffb6b6" />
        <path
          d="M585.26218,187.67421s8.02722-16.25754,7.88428-22.46415c-.14294-6.20661-4.52893-22.17827-6.37963-23.23151s-21.80963-11.18694-27.21878-8.14007-14.92809,13.4682-25.19506,16.28763c-2.90394,.79745-.36864,15.71588-.36864,15.71588l12.01448-1.73784s12.52606-11.24712,13.06773-3.58854c.54166,7.65857,12.15742,4.46876,12.15742,4.46876l.14294,6.20661,4.75464,.25579,1.30903-6.60533s2.13657,13.46645-.62443,20.47051,8.45603,2.36228,8.45603,2.36228Z"
          fill="#2f2e41"
        />
      </svg>
    );
  }
}
