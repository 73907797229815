import React, { Component } from "react";

export default class EducationImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          width="811"
          height="574"
          viewBox="0 0 811 304"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            id="b659e8b5-7e24-4e9b-ab8e-27842b197401-892"
            data-name="Path 438"
            d="M217.77176,566.343a17.58427,17.58427,0,0,0,16.98013-2.991c5.94728-4.99209,7.81231-13.21366,9.32907-20.82834L248.56858,520l-9.39515,6.46911c-6.75688,4.65223-13.66552,9.45394-18.34348,16.19234s-6.71882,15.93761-2.96132,23.22918"
            transform="translate(-194.5 -298)"
            fill="#e6e6e6"
          />
          <path
            id="bfce889d-851c-4599-b1ba-72c9c39792e6-893"
            data-name="Path 439"
            d="M219.22247,595.18022c-1.18251-8.61522-2.39918-17.34184-1.56772-26.04943.73684-7.73336,3.09625-15.2853,7.89965-21.48006a35.73253,35.73253,0,0,1,9.16783-8.30783c.91655-.57839,1.76033.874.84769,1.45016a33.97047,33.97047,0,0,0-13.43763,16.2125c-2.92549,7.44053-3.39524,15.55183-2.89133,23.456.30459,4.77987.95183,9.5284,1.60187,14.27133a.87009.87009,0,0,1-.58678,1.033.8449.8449,0,0,1-1.033-.58676Z"
            transform="translate(-194.5 -298)"
            fill="#f2f2f2"
          />
          <path
            id="ac886c1d-ef9d-4a77-9e33-50e4cbfa9f0b-894"
            data-name="Path 442"
            d="M227.73409,581.35626a12.94434,12.94434,0,0,0,11.27866,5.823c5.711-.271,10.47016-4.25524,14.754-8.039l12.67337-11.18963-8.38733-.40143c-6.03182-.28891-12.21929-.5599-17.96443,1.30232s-11.0435,6.337-12.09387,12.28369"
            transform="translate(-194.5 -298)"
            fill="#e6e6e6"
          />
          <path
            id="f8957600-c762-4aa2-be12-aeea5f20d384-895"
            data-name="Path 443"
            d="M215.87986,600.13256c5.69307-10.0732,12.296-21.26842,24.09536-24.84675a26.88752,26.88752,0,0,1,10.13421-1.04646c1.07613.093.80737,1.75136-.26652,1.659a24.97945,24.97945,0,0,0-16.1733,4.27878A42.03757,42.03757,0,0,0,222.55332,592.173c-1.841,2.80344-3.48986,5.72556-5.13878,8.64376C216.88767,601.74956,215.34683,601.076,215.87986,600.13256Z"
            transform="translate(-194.5 -298)"
            fill="#f2f2f2"
          />
          <path
            id="f5e70a17-e793-41c1-a2dd-69237c970f4c-896"
            data-name="Path 438"
            d="M738.52678,553.17582a24.21461,24.21461,0,0,0,23.38269-4.11877c8.18977-6.87442,10.758-18.196,12.84671-28.68191l6.17972-31.01657-12.93769,8.90836c-9.30465,6.40642-18.81827,13.01867-25.26012,22.29786s-9.25222,21.94707-4.07792,31.988"
            transform="translate(-194.5 -298)"
            fill="#e6e6e6"
          />
          <path
            id="b90780e1-a28a-4a8c-9c47-a4f5fb7f1aa8-897"
            data-name="Path 439"
            d="M740.5245,592.88638c-1.6284-11.86369-3.30382-23.88079-2.15885-35.87167,1.01467-10.64932,4.26374-21.04881,10.87831-29.57938a49.20592,49.20592,0,0,1,12.62466-11.44039c1.26215-.79648,2.42409,1.20354,1.16733,1.997a46.77943,46.77943,0,0,0-18.50445,22.32562c-4.02858,10.24607-4.67546,21.41582-3.98155,32.3003.41944,6.58217,1.31075,13.1212,2.20588,19.65251a1.19816,1.19816,0,0,1-.808,1.4225,1.16348,1.16348,0,0,1-1.42253-.808Z"
            transform="translate(-194.5 -298)"
            fill="#f2f2f2"
          />
          <path
            id="b244f669-d0df-4dab-b5dc-a763144460fd-898"
            data-name="Path 442"
            d="M752.24552,573.84993a17.82513,17.82513,0,0,0,15.53142,8.01861c7.8644-.37318,14.41806-5.85972,20.31712-11.07026l17.452-15.4088-11.54987-.55281c-8.30619-.39784-16.82672-.771-24.73813,1.79338s-15.20758,8.72639-16.654,16.91541"
            transform="translate(-194.5 -298)"
            fill="#e6e6e6"
          />
          <path
            id="e7416fa3-7efb-4d63-8b89-2e15a53e835a-899"
            data-name="Path 443"
            d="M735.92151,599.70607c7.83972-13.87143,16.93235-29.28794,33.1808-34.21552a37.02609,37.02609,0,0,1,13.95545-1.44105c1.48189.128,1.1118,2.41174-.367,2.28454a34.39829,34.39829,0,0,0-22.27164,5.89215c-6.27994,4.27453-11.16975,10.21755-15.30781,16.51907-2.53511,3.86051-4.80576,7.88445-7.07642,11.903C737.30934,601.93277,735.1875,601.00523,735.92151,599.70607Z"
            transform="translate(-194.5 -298)"
            fill="#f2f2f2"
          />
          <path
            d="M665.04913,344.4563a3.67458,3.67458,0,0,1-2.04749-4.441,1.76625,1.76625,0,0,0,.0799-.40753h0a1.84257,1.84257,0,0,0-3.31046-1.22119h0a1.766,1.766,0,0,0-.2039.36178,3.67459,3.67459,0,0,1-4.441,2.04749,1.76539,1.76539,0,0,0-.40754-.0799h0a1.84259,1.84259,0,0,0-1.2212,3.31046h0a1.76552,1.76552,0,0,0,.36181.20389,3.67462,3.67462,0,0,1,2.04748,4.441,1.76574,1.76574,0,0,0-.07991.40753h0A1.84257,1.84257,0,0,0,659.13728,350.3h0a1.76582,1.76582,0,0,0,.2039-.36179,3.67458,3.67458,0,0,1,4.441-2.04748,1.767,1.767,0,0,0,.40755.07989h0a1.84256,1.84256,0,0,0,1.22119-3.31045h0A1.76749,1.76749,0,0,0,665.04913,344.4563Z"
            transform="translate(-194.5 -298)"
            fill="#cbcbcb"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M735.04913,507.4563a3.67458,3.67458,0,0,1-2.04749-4.441,1.76625,1.76625,0,0,0,.0799-.40753h0a1.84257,1.84257,0,0,0-3.31046-1.22119h0a1.766,1.766,0,0,0-.2039.36178,3.67459,3.67459,0,0,1-4.441,2.04749,1.76539,1.76539,0,0,0-.40754-.0799h0a1.84259,1.84259,0,0,0-1.2212,3.31046h0a1.76552,1.76552,0,0,0,.36181.20389,3.67462,3.67462,0,0,1,2.04748,4.441,1.76574,1.76574,0,0,0-.07991.40753h0A1.84257,1.84257,0,0,0,729.13728,513.3h0a1.76582,1.76582,0,0,0,.2039-.36179,3.67458,3.67458,0,0,1,4.441-2.04748,1.767,1.767,0,0,0,.40755.07989h0a1.84256,1.84256,0,0,0,1.22119-3.31045h0A1.76749,1.76749,0,0,0,735.04913,507.4563Z"
            transform="translate(-194.5 -298)"
            fill="#f1f1f1"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M368.04913,412.4563a3.67458,3.67458,0,0,1-2.04749-4.441,1.76625,1.76625,0,0,0,.0799-.40753h0a1.84257,1.84257,0,0,0-3.31046-1.22119h0a1.766,1.766,0,0,0-.2039.36178,3.67459,3.67459,0,0,1-4.441,2.04749,1.76539,1.76539,0,0,0-.40754-.0799h0a1.84259,1.84259,0,0,0-1.2212,3.31046h0a1.76552,1.76552,0,0,0,.36181.20389,3.67462,3.67462,0,0,1,2.04748,4.441,1.76574,1.76574,0,0,0-.07991.40753h0A1.84257,1.84257,0,0,0,362.13728,418.3h0a1.76582,1.76582,0,0,0,.2039-.36179,3.67458,3.67458,0,0,1,4.441-2.04748,1.767,1.767,0,0,0,.40755.07989h0a1.84256,1.84256,0,0,0,1.22119-3.31045h0A1.76749,1.76749,0,0,0,368.04913,412.4563Z"
            transform="translate(-194.5 -298)"
            fill="#f1f1f1"
            style={{ isolation: "isolate" }}
          />
          <circle
            cx="326.65376"
            cy="50"
            r="6"
            fill="#f1f1f1"
            style={{ isolation: "isolate" }}
          />
          <circle cx="646.65376" cy="34" r="6" fill="#bf5700" />
          <circle cx="180.65376" cy="6" r="6" fill="#cbcbcb" />
          <circle cx="157.57996" cy="241.62342" r="43.06733" fill="#2f2e41" />
          <rect
            x="137.95433"
            y="275.42311"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <rect
            x="164.12183"
            y="275.42311"
            width="13.08373"
            height="23.44171"
            fill="#2f2e41"
          />
          <ellipse
            cx="148.85742"
            cy="299.1374"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="175.02496"
            cy="298.59223"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="393.18361"
            cy="515.50225"
            rx="23.89244"
            ry="7.50055"
            transform="translate(-443.88763 131.25771) rotate(-45.0221)"
            fill="#2f2e41"
          />
          <ellipse
            cx="300.47826"
            cy="518.50225"
            rx="7.50055"
            ry="23.89244"
            transform="translate(-473.06891 66.11269) rotate(-44.9779)"
            fill="#2f2e41"
          />
          <circle cx="155.76794" cy="235.5202" r="14.71921" fill="#fff" />
          <circle cx="155.76794" cy="235.5202" r="4.90643" fill="#3f3d56" />
          <path
            d="M361.95247,560.74273a9.57244,9.57244,0,0,1-18.83533,3.42884h0l-.00336-.0185c-.94177-5.20214,3.08039-7.043,8.28254-7.98474S361.01076,555.54065,361.95247,560.74273Z"
            transform="translate(-194.5 -298)"
            fill="#fff"
          />
          <path
            d="M394.31009,584.82347a3.6605,3.6605,0,0,0-3.65632,3.65632v7.63736a3.66049,3.66049,0,0,0,3.65632,3.65632l58.8298,0a3.66049,3.66049,0,0,0,3.65632-3.65631v-7.63737a3.6605,3.6605,0,0,0-3.65632-3.65632Z"
            transform="translate(-194.5 -298)"
            fill="#e5e5e5"
          />
          <path
            d="M428.142,579.7372a1.58575,1.58575,0,0,0-2.166.58036l-2.18619,3.78672-2.14238-4.23416a1.58551,1.58551,0,1,0-2.82945,1.43164l1.66723,3.2952h-.611V600h8.15454l0-15.403h-.8616l1.5553-2.6938A1.58577,1.58577,0,0,0,428.142,579.7372Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <path
            d="M350.97091,511.375a76.08526,76.08526,0,0,1-22.56494-3.44727,2.52873,2.52873,0,0,1-1.7522-2.39746V488a2.50294,2.50294,0,0,1,2.5-2.5h44a2.50294,2.50294,0,0,1,2.5,2.5v17.52a2.50606,2.50606,0,0,1-1.77881,2.39941A79.18482,79.18482,0,0,1,350.97091,511.375Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <path
            d="M375.15377,488v2.93l-23.81006,8.35a2.01556,2.01556,0,0,1-1.37012-.02l-22.81982-8.57V488a2.00583,2.00583,0,0,1,2-2h44A2.00583,2.00583,0,0,1,375.15377,488Z"
            transform="translate(-194.5 -298)"
            opacity="0.2"
          />
          <path
            d="M350.67745,495.8916a2.50248,2.50248,0,0,1-.87866-.15869l-34.10107-12.80469a2.50017,2.50017,0,0,1,.06958-4.70605l32.08544-10.97656a2.52519,2.52519,0,0,1,1.59961-.00684l35.81006,11.937a2.5,2.5,0,0,1,.03638,4.731l-33.79468,11.84472A2.49712,2.49712,0,0,1,350.67745,495.8916Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <polygon
            points="125.154 197 124.154 197 124.154 182.5 154.654 182.5 154.654 183.5 125.154 183.5 125.154 197"
            fill="#3f3d56"
          />
          <circle cx="124.65377" cy="198" r="2" fill="#3f3d56" />
          <circle cx="406.57996" cy="241.62342" r="43.06733" fill="#2f2e41" />
          <rect
            x="386.95433"
            y="275.42311"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <rect
            x="413.12183"
            y="275.42311"
            width="13.08373"
            height="23.44171"
            fill="#2f2e41"
          />
          <ellipse
            cx="397.85742"
            cy="299.1374"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="424.02496"
            cy="298.59223"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="642.18361"
            cy="515.50225"
            rx="23.89244"
            ry="7.50055"
            transform="translate(-370.8893 307.39519) rotate(-45.0221)"
            fill="#2f2e41"
          />
          <ellipse
            cx="556.47826"
            cy="556.50225"
            rx="23.89244"
            ry="7.50055"
            transform="translate(-412.52142 398.19135) rotate(-55.22521)"
            fill="#2f2e41"
          />
          <circle cx="404.76794" cy="235.5202" r="14.71921" fill="#fff" />
          <circle cx="409.76794" cy="230.5202" r="4.90643" fill="#3f3d56" />
          <path
            d="M616.9152,560.74273c1.44394,5.20124-3.81561,10.18525-11.74756,11.13208s-15.53264-2.502-16.97658-7.70324h0l-.00512-.0185c-1.43622-5.20214,4.69762-7.043,12.631-7.98474S615.47908,555.54065,616.9152,560.74273Z"
            transform="translate(-194.5 -298)"
            fill="#fff"
          />
          <path
            d="M586.01091,514.27649a76.08549,76.08549,0,0,1-22.22192,5.2198,2.52873,2.52873,0,0,1-2.52011-1.57069L554.729,501.66092a2.50294,2.50294,0,0,1,1.38685-3.25218l40.82342-16.41486a2.50294,2.50294,0,0,1,3.25218,1.38685l6.53611,16.25516a2.50606,2.50606,0,0,1-.75525,2.8898A79.18506,79.18506,0,0,1,586.01091,514.27649Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <path
            d="M599.7275,483.56726l1.09307,2.71846-18.976,16.62991a2.01554,2.01554,0,0,1-1.27867.49257l-24.36952.562-1.00355-2.4958a2.00583,2.00583,0,0,1,1.10948-2.60174l40.82343-16.41487A2.00584,2.00584,0,0,1,599.7275,483.56726Z"
            transform="translate(-194.5 -298)"
            opacity="0.2"
          />
          <path
            d="M579.96232,500.02039a2.50243,2.50243,0,0,1-.87443.18056l-36.41613.84167a2.50018,2.50018,0,0,1-1.69111-4.39226l25.67407-22.15407a2.52524,2.52524,0,0,1,1.48158-.6031l37.678-2.28427a2.5,2.5,0,0,1,1.7987,4.37584L580.677,499.582A2.497,2.497,0,0,1,579.96232,500.02039Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <polygon
            points="356.346 212.767 355.418 213.14 350.009 199.687 378.307 188.308 378.68 189.236 351.309 200.242 356.346 212.767"
            fill="#3f3d56"
          />
          <circle cx="356.25493" cy="213.88135" r="2" fill="#3f3d56" />
          <path
            d="M765.03745,437.869A76.08531,76.08531,0,0,1,747.704,423.016a2.52874,2.52874,0,0,1-.22029-2.96133l9.26552-14.88156a2.50294,2.50294,0,0,1,3.44362-.80091l37.35187,23.25592a2.503,2.503,0,0,1,.80091,3.44363l-9.2601,14.87285a2.50607,2.50607,0,0,1-2.77823,1.0967A79.18479,79.18479,0,0,1,765.03745,437.869Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <path
            d="M797.92113,430.80747l-1.54863,2.48728-24.62586-5.49625a2.0156,2.0156,0,0,1-1.15252-.74116l-14.84226-19.33641,1.42178-2.28356a2.00583,2.00583,0,0,1,2.7549-.64072l37.35187,23.25592A2.00583,2.00583,0,0,1,797.92113,430.80747Z"
            transform="translate(-194.5 -298)"
            opacity="0.2"
          />
          <path
            d="M772.972,424.56989a2.50263,2.50263,0,0,1-.662-.59912l-22.18077-28.89389a2.50018,2.50018,0,0,1,2.54642-3.95822l33.03913,7.64049a2.52525,2.52525,0,0,1,1.36153.83966l24.09015,29.06058a2.50005,2.50005,0,0,1-2.46964,4.03537l-34.949-7.8069A2.49731,2.49731,0,0,1,772.972,424.56989Z"
            transform="translate(-194.5 -298)"
            fill="#bf5700"
          />
          <polygon
            points="552.607 109.416 551.758 108.887 559.422 96.578 585.314 112.699 584.785 113.547 559.742 97.955 552.607 109.416"
            fill="#3f3d56"
          />
          <circle cx="551.65404" cy="110.00027" r="2" fill="#3f3d56" />
          <circle cx="674.72758" cy="192.62342" r="43.06733" fill="#2f2e41" />
          <rect
            x="893.07258"
            y="514.64598"
            width="13.08374"
            height="23.44171"
            transform="translate(-336.98367 248.35991) rotate(-31.43113)"
            fill="#2f2e41"
          />
          <rect
            x="870.74471"
            y="528.29163"
            width="13.08373"
            height="23.44171"
            transform="translate(-347.37573 238.71877) rotate(-31.43113)"
            fill="#2f2e41"
          />
          <ellipse
            cx="902.14742"
            cy="538.87471"
            rx="10.90314"
            ry="4.08868"
            transform="translate(-343.13452 251.51609) rotate(-31.43113)"
            fill="#2f2e41"
          />
          <ellipse
            cx="879.53522"
            cy="552.05521"
            rx="10.90314"
            ry="4.08868"
            transform="translate(-353.32574 241.65844) rotate(-31.43113)"
            fill="#2f2e41"
          />
          <ellipse
            cx="828.12393"
            cy="466.50225"
            rx="7.50055"
            ry="23.89244"
            transform="translate(-281.91356 423.85434) rotate(-44.9779)"
            fill="#2f2e41"
          />
          <ellipse
            cx="913.82928"
            cy="507.50225"
            rx="7.50055"
            ry="23.89244"
            transform="translate(-320.74534 313.8443) rotate(-34.77479)"
            fill="#2f2e41"
          />
          <circle cx="661.5396" cy="180.5202" r="14.71921" fill="#fff" />
          <circle cx="656.5396" cy="175.5202" r="4.90643" fill="#3f3d56" />
          <path
            d="M837.08533,503.07331c-2.79112,4.573.92772,10.75961,8.30629,13.81815s15.62275,1.83086,18.41387-2.74214h0l.00991-.01627c2.78393-4.576-2.62325-7.99955-10.00454-11.05358S839.86916,498.49737,837.08533,503.07331Z"
            transform="translate(-194.5 -298)"
            fill="#fff"
          />
          <path
            d="M854.77685,436.54712c3.84558-15.487,20.82056-24.60077,37.91474-20.35617s27.83429,20.24029,23.9887,35.72729-16.60394,15.537-33.69812,11.29236S850.93127,452.03415,854.77685,436.54712Z"
            transform="translate(-194.5 -298)"
            fill="#e6e6e6"
          />
          <path
            d="M1004.5,602h-809a1,1,0,0,1,0-2h809a1,1,0,0,1,0,2Z"
            transform="translate(-194.5 -298)"
            fill="#cbcbcb"
          />
        </svg>
      </div>
    );
  }
}
