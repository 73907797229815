import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./DataScienceImg";
import Backend from "./Backend";
import Distributed from "./Distributed";
import Cryptography from "./Cryptography";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <DataScienceImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <Backend theme={props.theme} />;
  else if (props.fileName === "CloudInfraImg")
    return <Distributed theme={props.theme} />;
  return <Cryptography theme={props.theme} />;
}

function generateRandomInteger(low, high) {
  const lowCeil = Math.ceil(low);
  const highFloor = Math.floor(high);
  const randomFloat = lowCeil + Math.random() * (highFloor - lowCeil);

  return Math.floor(randomFloat);
}

class SkillSection extends Component {
  render() {
    var RandomNumber = generateRandomInteger(0, 100);
    const theme = this.props.theme;
    if (RandomNumber % 2 == 0) {
      return (
        <div>
          {skills.data.map((skill) => {
            return (
              <div className="skills-main-div">
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    {/* <img
                      alt="Ashutosh is Analysing Data"
                      src={require(`../../assests/images/${skill.imagePath}`)}
                    ></img> */}
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>
                <div className="skills-text-div">
                  <Fade right duration={1000}>
                    <h1
                      className="skills-heading"
                      style={{ color: theme.text }}
                    >
                      {skill.title}
                    </h1>
                  </Fade>
                  <Fade right duration={1500}>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Fade>
                  <Fade right duration={2000}>
                    <div>
                      {skill.skills.map((skillSentence) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            style={{ color: theme.secondaryText }}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          {skills.data.map((skill) => {
            return (
              <div className="skills-main-div">
                <div className="skills-text-div">
                  <Fade left duration={1000}>
                    <h1
                      className="skills-heading"
                      style={{ color: theme.text }}
                    >
                      {skill.title}
                    </h1>
                  </Fade>
                  <Fade left duration={1500}>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Fade>
                  <Fade left duration={2000}>
                    <div>
                      {skill.skills.map((skillSentence) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            style={{ color: theme.secondaryText }}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                  </Fade>
                </div>

                <Fade right duration={2000}>
                  <div className="skills-image-div">
                    {/* <img
                      alt="Ashutosh is Analysing Data"
                      src={require(`../../assests/images/${skill.imagePath}`)}
                    ></img> */}
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default SkillSection;
